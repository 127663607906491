import React, { useState, useEffect } from "react";
import { Link } from "react-scroll"; 

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down
      setIsScrolled(window.scrollY > 0);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const handleScroll = () => {
  //   if (window.scrollY > window.innerHeight) {
  //     setIsScrolled(true);
  //   } else {
  //     setIsScrolled(false);
  //   }
  // };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const navLinks = [
    { name: "Home", to: "home" },
    { name: "About", to: "about" },
    { name: "Services", to: "services" },
    { name: "Gallery", to: "gallery" },
    { name: "Contact", to: "contact" },
  ];

  return (
    <nav
    className={`fixed top-0 w-full z-40 transition-colors duration-300 ${
      isScrolled ? "bg-[#121212]" : "bg-black bg-opacity-25"
    }`}
  >
      <div className="items-center flex justify-between h-20 max-w-[1300px] mx-auto">
        <div className="logo">
          <Link to="home" smooth={true} duration={500}>
            <img src="https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/Torque-Logo1.png" alt="Torque Logo" className="w-24 cursor-pointer" />
          </Link>
        </div>

        <div className="flex items-center">
          {/* Desktop Navigation Links */}
          <div className="hidden md:flex gap-[2em] items-center">
            {navLinks.map((link, index) => (
              <div key={index} className="link">
                <Link
                  to={link.to}
                  smooth={true}
                  duration={500}
                  className="cursor-pointer text-white uppercase font-bold hover:text-[#A0CF3B]"
                >
                  {link.name}
                </Link>
              </div>
            ))}
            <a
              href="https://l.instagram.com/?u=https%3A%2F%2Finsider.in%2Fthenisai-thendral-deva-live-in-concert-feb15-2025%2Fevent%3Ffbclid%3DPAZXh0bgNhZW0CMTEAAaaBVQuOqBfnCbROzaE1sVm7eLNHSZBg7It9f3TyPrxf9vxZto9ebpe7i5A_aem_fVG9YiMJPGvtz7uL1EDyNA&e=AT2zacCXUWpzYfWY18idfWTWMYp1Oai5_CpYtsytCRM7Y5P1eWOejqakcY8hPVlq0Oie3JsIQFeoV2xp3hBZwkr2Fw32YHOy_a31WuY0b15cVvAW9XFjyC8" target="_blank"
              className="bg-[#A0CF3B] text-black uppercase font-bold py-2 px-4 rounded hover:bg-[#8DBA32] transition-colors duration-300"
            >
              Buy Tickets
            </a>
          </div>
          <button
            onClick={handleMenuToggle}
            className="block md:hidden text-white focus:outline-none mr-2 rounded"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="absolute top-full right-0 w-full h-[100vh] text-[#A0CF3B] bg-[#121212] flex flex-col items-center md:hidden">
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              smooth={true}
              duration={500}
              onClick={() => setIsMenuOpen(false)}
              className="block py-2 cursor-pointer uppercase text-xl font-bold"
            >
              {link.name}
            </Link>
          ))}
          <a
            href="https://l.instagram.com/?u=https%3A%2F%2Finsider.in%2Fthenisai-thendral-deva-live-in-concert-feb15-2025%2Fevent%3Ffbclid%3DPAZXh0bgNhZW0CMTEAAaaBVQuOqBfnCbROzaE1sVm7eLNHSZBg7It9f3TyPrxf9vxZto9ebpe7i5A_aem_fVG9YiMJPGvtz7uL1EDyNA&e=AT2zacCXUWpzYfWY18idfWTWMYp1Oai5_CpYtsytCRM7Y5P1eWOejqakcY8hPVlq0Oie3JsIQFeoV2xp3hBZwkr2Fw32YHOy_a31WuY0b15cVvAW9XFjyC8"
            className="bg-[#A0CF3B] text-black uppercase font-bold py-2 px-4 mt-4 rounded hover:bg-[#8DBA32] transition-colors duration-300"
          >
            Buy Tickets
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
