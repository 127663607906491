import React, { useState, useEffect } from "react";
import bannerImg from '../Assests/deva-concert.jpeg';

const images = ["https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/01.jpg", "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/02.jpg", "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/03.jpg", "https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/04.jpg"];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    handleResize(); 
    window.addEventListener("resize", handleResize); 

    return () => window.removeEventListener("resize", handleResize); 
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden home">
      {isMobile && (
        <div className="text-center py-16 mt-12 text-white">
          <h1 className="text-4xl font-bold">Welcome <br />to <br /> <span className="text-[#A0CF3B]">Torque Entertainment</span></h1>
          <p className="text-lg mt-2">Experience the unexpected with us!</p>
        </div>
      )}
      <div className="-mt-6 md:-mt-0 p-2 md:p-0">
            <img
              // src='https://d96s5z7k1zdhk.cloudfront.net/torque_entertainment/500KB.jpg'
              src={bannerImg}
              alt=''
              className="w-full h-full  rounded-xl md:rounded "
            />
          </div>
      {/*<div
        className="flex transition-transform duration-700"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
         {images.map((image, index) => (
          <div key={index} className="w-full flex-shrink-0 py-0 md:py-20 p-2 md:p-0">
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-full object-cover rounded-xl md:rounded"
            />
          </div>
        ))}
      </div>*/}
    </div>
  );
};

export default Home;
